/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html, body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #133758;
  font-family: "Oswald", sans-serif; }

.container {
  margin: auto;
  max-width: 976px;
  background-color: #B9E7FF; }
  .container .app .hr {
    border-radius: 20px;
    background-color: #133758;
    display: block;
    height: 5px;
    margin: auto;
    width: 700px; }
    @media (min-width: 730px) and (max-width: 975px) {
      .container .app .hr {
        width: 100%; } }
    @media (max-width: 729px) {
      .container .app .hr {
        width: 100%; } }
  .container .app .relative {
    position: relative; }
  .container .header {
    padding: 30px 0 40px;
    z-index: 11;
    position: relative; }
    .container .header .title {
      font-family: "Rubik", sans-serif;
      font-weight: 900;
      text-align: center;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      font-size: 110px;
      letter-spacing: 0;
      cursor: pointer; }
      @media (max-width: 729px) {
        .container .header .title {
          font-size: 60px; } }
    .container .header .subtitle {
      font-size: 30px;
      letter-spacing: 0;
      line-height: 40px;
      text-align: center;
      margin: 20px 0 0;
      padding: 0;
      text-transform: uppercase; }
      @media (max-width: 729px) {
        .container .header .subtitle {
          font-size: 17px;
          line-height: 25px; } }
    .container .header.day {
      background-color: #B9E7FF; }
    .container .header.night {
      background-color: #133758; }
      .container .header.night .title {
        color: #B9E7FF; }
      .container .header.night .subtitle {
        color: #B9E7FF; }

.animate-appear {
  opacity: 0.01; }
  .animate-appear.animate-appear-active {
    opacity: 1;
    transition: opacity 300ms ease-in; }

.animate-enter {
  opacity: 0.01; }
  .animate-enter.animate-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in; }

.animate-leave {
  opacity: 1; }
  .animate-leave.animate-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in; }

.story-animate-appear {
  opacity: 0.01; }
  .story-animate-appear.animate-appear-active {
    opacity: 1;
    transition: opacity 150ms ease-in; }

.story-animate-enter {
  opacity: 0.01; }
  .story-animate-enter.animate-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in; }

.story-animate-leave {
  opacity: 1; }
  .story-animate-leave.animate-leave-active {
    opacity: 0.01;
    transition: opacity 150ms ease-in; }

.time_select {
  width: 800px;
  margin: auto; }
  @media (min-width: 730px) and (max-width: 975px) {
    .time_select {
      width: 100%;
      padding: 10px; } }
  @media (max-width: 729px) {
    .time_select {
      width: 100%;
      padding: 10px; } }
  .time_select .clouds {
    display: block;
    height: 45px;
    margin: 0 auto 47px;
    background: url("../img/icons/cloud_division.svg") no-repeat center center;
    width: 760px; }
    @media (min-width: 730px) and (max-width: 975px) {
      .time_select .clouds {
        width: 100%; } }
    @media (max-width: 729px) {
      .time_select .clouds {
        width: 100%; } }
  .time_select .paragraph {
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    margin: 0 0 42px;
    padding: 0;
    text-align: center;
    text-transform: uppercase; }
  .time_select .form {
    padding-bottom: 40px;
    position: relative; }
    .time_select .form .hour_icon {
      position: absolute;
      width: 100%;
      height: 100%; }
      @media (max-width: 729px) {
        .time_select .form .hour_icon {
          display: none; } }
      .time_select .form .hour_icon .icon {
        display: block;
        background: url("../img/icons/Tod_24hrs_Marker.svg") no-repeat center center;
        background-size: 70px 70px;
        height: 70px;
        width: 70px;
        margin: 105px auto 0; }
    .time_select .form .inputs {
      overflow: hidden;
      position: relative; }
      .time_select .form .inputs .col {
        width: 250px;
        text-align: center;
        padding-bottom: 33px; }
        @media (max-width: 729px) {
          .time_select .form .inputs .col {
            padding-bottom: 55px; } }
        .time_select .form .inputs .col .icon {
          margin: 0 auto 20px;
          display: block; }
        .time_select .form .inputs .col.wake {
          margin-left: 50px;
          float: left; }
          @media (max-width: 729px) {
            .time_select .form .inputs .col.wake {
              float: none;
              display: block;
              width: 100%;
              margin-left: 0; } }
          .time_select .form .inputs .col.wake .up {
            left: -50px; }
            @media (max-width: 729px) {
              .time_select .form .inputs .col.wake .up {
                right: 0;
                left: auto; } }
          .time_select .form .inputs .col.wake .down {
            left: -50px; }
            @media (max-width: 729px) {
              .time_select .form .inputs .col.wake .down {
                left: 0;
                right: auto; } }
        .time_select .form .inputs .col.sleep {
          margin-right: 50px;
          float: right; }
          @media (max-width: 729px) {
            .time_select .form .inputs .col.sleep {
              float: none;
              display: block;
              width: 100%;
              margin-right: 0; } }
          .time_select .form .inputs .col.sleep .up {
            right: -50px; }
            @media (max-width: 729px) {
              .time_select .form .inputs .col.sleep .up {
                right: 0;
                left: auto; } }
          .time_select .form .inputs .col.sleep .down {
            right: -50px; }
            @media (max-width: 729px) {
              .time_select .form .inputs .col.sleep .down {
                left: 0;
                right: auto; } }
        .time_select .form .inputs .col .control {
          position: relative;
          width: 180px;
          height: 81px;
          margin: auto; }
          .time_select .form .inputs .col .control .up {
            cursor: pointer;
            position: absolute;
            background: url("../img/icons/Tod_Clock_Plus.svg") no-repeat center center;
            background-size: 29px 29px;
            height: 29px;
            width: 29px;
            top: 0; }
            @media (max-width: 729px) {
              .time_select .form .inputs .col .control .up {
                top: auto;
                bottom: -45px; } }
          .time_select .form .inputs .col .control .down {
            cursor: pointer;
            position: absolute;
            background: url("../img/icons/Tod_Clock_Minus.svg") no-repeat center center;
            background-size: 29px 29px;
            height: 29px;
            width: 29px;
            bottom: 0; }
            @media (max-width: 729px) {
              .time_select .form .inputs .col .control .down {
                top: auto;
                bottom: -45px; } }
          .time_select .form .inputs .col .control .input {
            border: 2px solid black;
            width: 100%;
            height: 100%; }
            .time_select .form .inputs .col .control .input input {
              margin-top: -6px;
              background: transparent;
              color: #133758;
              font-family: "Oswald", sans-serif;
              font-size: 58px;
              font-weight: 700;
              text-align: center;
              border: none;
              width: 100%;
              height: 100%;
              padding: 0; }
              .time_select .form .inputs .col .control .input input:focus {
                outline-width: 0; }
        .time_select .form .inputs .col label {
          display: block;
          font-family: "Rubik", sans-serif;
          font-weight: 700;
          font-size: 27px;
          line-height: 32px;
          text-transform: uppercase;
          padding-bottom: 35px; }
    .time_select .form button {
      color: #133758;
      font-family: "Oswald", sans-serif;
      font-weight: 700;
      cursor: pointer;
      display: block;
      font-size: 27px;
      width: 200px;
      height: 90px;
      margin: auto;
      border: 0;
      padding: 10px;
      background-color: yellow;
      text-transform: uppercase;
      line-height: 30px;
      position: relative; }

.loading {
  width: 100%;
  height: 100%;
  z-index: 31;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center; }
  .loading .subtitle {
    font-size: 30px;
    text-transform: uppercase;
    margin: 50px auto;
    font-weight: 300;
    line-height: 40px;
    width: 600px; }
    @media (max-width: 729px) {
      .loading .subtitle {
        font-size: 17px;
        line-height: 25px;
        width: 100%;
        padding: 10px; } }
  .loading .time_machine {
    width: 285px;
    height: 236px; }
    @media (max-width: 729px) {
      .loading .time_machine {
        width: 100%; } }

.overlay {
  width: 100%;
  height: 100%;
  background-color: #ffffc5;
  background-color: rgba(255, 255, 197, 0.8);
  top: 0;
  left: 0;
  position: absolute;
  z-index: 30; }

.timeline-container {
  position: relative; }
  .timeline-container .timeline .gradient {
    display: block;
    height: 130px;
    width: 100%;
    background: repeat-x top left;
    background-size: 260px 130px; }
    .timeline-container .timeline .gradient.night {
      background-image: url("../img/stories/environment/Env-horizon.svg"); }
    .timeline-container .timeline .gradient.day {
      background-image: url("../img/stories/environment/Env-sunrise.svg"); }
  .timeline-container .timeline.night {
    background-color: #133758; }
  .timeline-container .timeline.day {
    background-color: #B9E7FF; }
  .timeline-container .timeline .hour {
    padding-left: 17px;
    width: 100%;
    display: block;
    height: 335px;
    position: relative; }
    .timeline-container .timeline .hour.stories_0 {
      height: 300px; }
    @media (min-width: 730px) and (max-width: 975px) {
      .timeline-container .timeline .hour {
        position: static;
        min-height: 350px;
        height: auto;
        padding-top: 40px; } }
    @media (max-width: 729px) {
      .timeline-container .timeline .hour {
        position: static;
        min-height: 350px;
        height: auto;
        padding-top: 40px; } }
    .timeline-container .timeline .hour .position_4 {
      display: none; }
    @media (max-width: 729px) {
      .timeline-container .timeline .hour.stories_0 .stories .position_1.story {
        display: block; } }
    @media (min-width: 730px) and (max-width: 975px) {
      .timeline-container .timeline .hour.stories_0 .stories .position_3, .timeline-container .timeline .hour.stories_0 .stories .position_4 {
        display: none; }
      .timeline-container .timeline .hour.stories_1 .stories .environment {
        display: none; }
        .timeline-container .timeline .hour.stories_1 .stories .environment.position_4 {
          display: inline-block; }
      .timeline-container .timeline .hour.stories_2 .stories .environment {
        display: none; }
      .timeline-container .timeline .hour.stories_4 .stories .story {
        display: inline-block; } }
    .timeline-container .timeline .hour.day {
      background-color: #B9E7FF;
      color: #133758; }
      .timeline-container .timeline .hour.day .footer {
        color: #CC3300; }
        .timeline-container .timeline .hour.day .footer .button {
          position: absolute;
          top: 0;
          left: -10px;
          width: 35px;
          height: 35px;
          background: url("../img/icons/ToD_o_button.svg") no-repeat bottom left;
          background-size: 35px 35px; }
          .timeline-container .timeline .hour.day .footer .button.open {
            background-image: url("../img/icons/ToD_o_button_selected.svg"); }
      .timeline-container .timeline .hour.day.hidden .story:hover:not(.open) .footer .button {
        background-image: url("../img/icons/ToD_o_button.svg"); }
      .timeline-container .timeline .hour.day .story:hover:not(.open) .footer .button {
        background-image: url("../img/icons/ToD_o_button_rollover.svg"); }
    .timeline-container .timeline .hour.night {
      background-color: #133758;
      color: #B9E7FF; }
      .timeline-container .timeline .hour.night .footer {
        color: #FFFF00; }
        .timeline-container .timeline .hour.night .footer .button {
          position: absolute;
          top: 0;
          left: -10px;
          width: 35px;
          height: 35px;
          background: url("../img/icons/ToD_y_button.svg") no-repeat bottom left;
          background-size: 35px 35px; }
          .timeline-container .timeline .hour.night .footer .button.open {
            background-image: url("../img/icons/ToD_y_button_selected.svg"); }
      .timeline-container .timeline .hour.night.hidden .story:hover:not(.open) .footer .button {
        background-image: url("../img/icons/ToD_y_button.svg"); }
      .timeline-container .timeline .hour.night .story:hover:not(.open) .footer .button {
        background-image: url("../img/icons/ToD_y_button_rollover.svg"); }
        .timeline-container .timeline .hour.night .story:hover:not(.open) .footer .button.open {
          background-image: url("../img/icons/ToD_y_button_selected.svg"); }
    .timeline-container .timeline .hour.hidden .time {
      opacity: 0.2; }
    .timeline-container .timeline .hour.hidden .stories .story {
      opacity: 0.2; }
      .timeline-container .timeline .hour.hidden .stories .story.open {
        opacity: 1;
        z-index: 9; }
        .timeline-container .timeline .hour.hidden .stories .story.open.interactive {
          cursor: pointer; }
      .timeline-container .timeline .hour.hidden .stories .story.interactive {
        cursor: default; }
    .timeline-container .timeline .hour.hidden.selected .time {
      opacity: 1; }
    .timeline-container .timeline .hour.selected .time .digits {
      background: url("../img/icons/time_highlight_frame.svg") no-repeat center center; }
    .timeline-container .timeline .hour.selected.day .time .digits {
      background: url("../img/icons/time_highlight_frame_dark.svg") no-repeat center center; }
    .timeline-container .timeline .hour .time {
      position: absolute;
      bottom: 60px;
      width: 100%;
      font-weight: bold;
      font-size: 30px;
      transition: opacity 300ms ease-in; }
      .timeline-container .timeline .hour .time .digits {
        padding: 19px; }
      @media (min-width: 730px) and (max-width: 975px) {
        .timeline-container .timeline .hour .time {
          position: static;
          text-align: center; } }
      @media (max-width: 729px) {
        .timeline-container .timeline .hour .time {
          position: static;
          text-align: center; } }
    @media (min-width: 976px) {
      .timeline-container .timeline .hour .stories {
        margin-left: 135px; } }
    @media (min-width: 730px) and (max-width: 975px) {
      .timeline-container .timeline .hour .stories {
        padding: 0 90px;
        text-align: center; } }
    .timeline-container .timeline .hour .stories .average {
      position: absolute;
      left: 36px;
      width: 61px;
      z-index: 13;
      font-size: 25px; }
      @media (min-width: 730px) and (max-width: 975px) {
        .timeline-container .timeline .hour .stories .average {
          position: static;
          width: 100%;
          text-align: center;
          padding: 25px 0; } }
      @media (max-width: 729px) {
        .timeline-container .timeline .hour .stories .average {
          position: static;
          width: 100%;
          text-align: center;
          padding: 25px 0; } }
      .timeline-container .timeline .hour .stories .average .av_time {
        font-weight: 700; }
      .timeline-container .timeline .hour .stories .average p {
        font-size: 16px; }
    .timeline-container .timeline .hour .stories .story {
      width: 260px;
      position: absolute;
      color: #133758;
      transition: opacity 300ms ease-in;
      z-index: 4; }
      .timeline-container .timeline .hour .stories .story .hide {
        display: none; }
      @media (min-width: 730px) and (max-width: 975px) {
        .timeline-container .timeline .hour .stories .story {
          position: static;
          height: auto;
          display: inline-block;
          vertical-align: top; } }
      @media (max-width: 729px) {
        .timeline-container .timeline .hour .stories .story {
          position: relative;
          width: 100%; } }
      .timeline-container .timeline .hour .stories .story.interactive {
        cursor: pointer; }
      .timeline-container .timeline .hour .stories .story.environment {
        z-index: 3; }
        @media (max-width: 729px) {
          .timeline-container .timeline .hour .stories .story.environment {
            display: none; } }
      .timeline-container .timeline .hour .stories .story.position_1 {
        left: 135px; }
        @media (min-width: 730px) and (max-width: 975px) {
          .timeline-container .timeline .hour .stories .story.position_1 {
            left: auto; } }
        @media (max-width: 729px) {
          .timeline-container .timeline .hour .stories .story.position_1 {
            left: auto; } }
      .timeline-container .timeline .hour .stories .story.position_2 {
        left: 395px; }
        @media (min-width: 730px) and (max-width: 975px) {
          .timeline-container .timeline .hour .stories .story.position_2 {
            left: auto; } }
        @media (max-width: 729px) {
          .timeline-container .timeline .hour .stories .story.position_2 {
            left: auto; } }
        .timeline-container .timeline .hour .stories .story.position_2 .extra.left {
          right: 0;
          left: auto; }
          .timeline-container .timeline .hour .stories .story.position_2 .extra.left .text-container {
            right: auto;
            left: 25px; }
      .timeline-container .timeline .hour .stories .story.position_3 {
        left: 655px; }
        @media (min-width: 730px) and (max-width: 975px) {
          .timeline-container .timeline .hour .stories .story.position_3 {
            left: auto; } }
        @media (max-width: 729px) {
          .timeline-container .timeline .hour .stories .story.position_3 {
            left: auto; } }
        .timeline-container .timeline .hour .stories .story.position_3 .extra {
          right: 0;
          left: auto; }
        .timeline-container .timeline .hour .stories .story.position_3 .text-container {
          right: auto;
          left: 25px; }
      @media (min-width: 730px) and (max-width: 975px) {
        .timeline-container .timeline .hour .stories .story .inner-story {
          display: block;
          width: 260px;
          position: relative;
          margin: auto; } }
      @media (max-width: 729px) {
        .timeline-container .timeline .hour .stories .story .inner-story {
          display: block;
          width: 260px;
          position: relative;
          margin: auto;
          height: 340px; } }
      .timeline-container .timeline .hour .stories .story .main {
        width: 260px;
        height: 300px;
        position: absolute;
        top: 0;
        left: 0; }
        @media (max-width: 729px) {
          .timeline-container .timeline .hour .stories .story .main {
            position: static; } }
        @media (min-width: 730px) and (max-width: 975px) {
          .timeline-container .timeline .hour .stories .story .main {
            position: static; } }
      .timeline-container .timeline .hour .stories .story .extra {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 300px;
        width: 520px;
        z-index: 100; }
        @media (max-width: 729px) {
          .timeline-container .timeline .hour .stories .story .extra {
            width: 260px; } }
        @media (min-width: 730px) and (max-width: 975px) {
          .timeline-container .timeline .hour .stories .story .extra {
            width: 260px; } }
        .timeline-container .timeline .hour .stories .story .extra .desktop {
          position: absolute;
          top: 0;
          left: 0;
          height: 300px;
          width: 600px; }
          @media (max-width: 729px) {
            .timeline-container .timeline .hour .stories .story .extra .desktop {
              display: none; } }
          @media (min-width: 730px) and (max-width: 975px) {
            .timeline-container .timeline .hour .stories .story .extra .desktop {
              display: none; } }
        @media (min-width: 976px) {
          .timeline-container .timeline .hour .stories .story .extra .mobile {
            display: none; } }
        @media (max-width: 729px) {
          .timeline-container .timeline .hour .stories .story .extra .mobile {
            position: absolute;
            top: 0;
            left: 0;
            width: 260px;
            height: 600px; } }
        @media (min-width: 730px) and (max-width: 975px) {
          .timeline-container .timeline .hour .stories .story .extra .mobile {
            position: absolute;
            top: 0;
            left: 0;
            width: 260px;
            height: 600px; } }
        .timeline-container .timeline .hour .stories .story .extra .text-container {
          position: absolute;
          top: 40px;
          right: 25px;
          width: 225px;
          display: block; }
          @media (max-width: 729px) {
            .timeline-container .timeline .hour .stories .story .extra .text-container {
              top: 375px; } }
          @media (min-width: 730px) and (max-width: 975px) {
            .timeline-container .timeline .hour .stories .story .extra .text-container {
              top: 375px; } }
          .timeline-container .timeline .hour .stories .story .extra .text-container .headline {
            font-size: 25px;
            display: block;
            padding-bottom: 10px; }
          .timeline-container .timeline .hour .stories .story .extra .text-container .paragraph {
            display: block;
            font-size: 14px;
            line-height: 20px; }
        @media (min-width: 976px) {
          .timeline-container .timeline .hour .stories .story .extra.bottom .text-container {
            top: 100px; } }
      .timeline-container .timeline .hour .stories .story .footer {
        padding: 0 30px 0 30px;
        display: block;
        position: absolute;
        top: 290px;
        z-index: 7;
        text-align: left; }
        @media (min-width: 730px) and (max-width: 975px) {
          .timeline-container .timeline .hour .stories .story .footer {
            position: relative;
            top: auto;
            margin-top: -10px; } }
        .timeline-container .timeline .hour .stories .story .footer .headline {
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 2px;
          display: block;
          text-transform: uppercase; }
      .timeline-container .timeline .hour .stories .story.static .main {
        position: absolute;
        top: 100px;
        z-index: 5; }
      .timeline-container .timeline .hour .stories .story.static .footer {
        position: absolute;
        top: 280px;
        padding: 0 60px; }
  .timeline-container .footer.hidden .stars, .timeline-container .footer.hidden .paragraph, .timeline-container .footer.hidden .sources, .timeline-container .footer.hidden .desktop, .timeline-container .footer.hidden .mobile {
    opacity: 0.3; }
  .timeline-container .footer .stars {
    padding-left: 140px; }
    @media (max-width: 729px) {
      .timeline-container .footer .stars {
        padding-left: 0;
        text-align: center; } }
    @media (min-width: 730px) and (max-width: 975px) {
      .timeline-container .footer .stars {
        padding-left: 0;
        text-align: center; } }
    .timeline-container .footer .stars img {
      width: 260px;
      height: 130px; }
      @media (min-width: 730px) and (max-width: 975px) {
        .timeline-container .footer .stars img.stars_3 {
          display: none; } }
      @media (max-width: 729px) {
        .timeline-container .footer .stars img.stars_3 {
          display: none; } }
      @media (max-width: 729px) {
        .timeline-container .footer .stars img.stars_2 {
          display: none; } }
  .timeline-container .footer .paragraph {
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: #FFFF00;
    font-size: 18px;
    text-transform: uppercase; }
  .timeline-container .footer .sources {
    width: 100%;
    padding: 10px;
    text-align: center;
    color: #FFFF00;
    font-size: 12px; }
    .timeline-container .footer .sources a, .timeline-container .footer .sources a:hover {
      color: #FFFF00;
      text-decoration: none; }
  .timeline-container .footer.day {
    background-color: #B9E7FF; }
    .timeline-container .footer.day .paragraph {
      background-color: #CC3300;
      color: #B9E7FF; }
    .timeline-container .footer.day .sources {
      color: #CC3300; }
      .timeline-container .footer.day .sources a, .timeline-container .footer.day .sources a:hover {
        color: #CC3300; }
  .timeline-container .footer.night {
    background-color: #133758; }
  .timeline-container .footer .desktop {
    height: 280px;
    width: 100%; }
    @media (max-width: 729px) {
      .timeline-container .footer .desktop {
        display: none; } }
  .timeline-container .footer .mobile {
    display: none;
    width: 100%;
    height: 270px; }
    @media (max-width: 729px) {
      .timeline-container .footer .mobile {
        display: block; } }
